import * as S from "./styles"
import { AiOutlineWhatsApp } from "react-icons/ai"
import { useWhatsApp } from "../../contexts/WhatsAppProvider"
import { useState } from "react"
import { formatPrice } from "../../utils/formatPrice"
import Link from "next/link"
import Image from "next/image"

export function CardProduct({ item, state }) {
  const { handleOpenGetInformation } = useWhatsApp()
  const [buttonHover, setButtonHover] = useState(false)

  const itemState =
    state?.find((itemState) => parseInt(itemState.prod_cod) === parseInt(item.prod_cod)) ??
    state?.find((itemState) => parseInt(itemState.codigo_produto) === parseInt(item.codigo_produto))

  const listCoresWith5 = itemState?.list_cores?.slice(0, 5) || item?.list_cores?.slice(0, 5)
  const restCoresLength = itemState?.list_cores?.length - 5 || item?.list_cores?.length - 5

  const indisponivel = parseInt(itemState?.estoque) === 0

  function getFlag(item) {
    const ultraRapido = item.ultrarapido === "S"
    const novidade = item.selo === "S" || item.segmento === "" || item.segmento === null

    if (ultraRapido) {
      return {
        backgroundColor: "#F5F5F5",
        color: "#FCA62E",
        text: "Pronto em 48 hrs",
      }
    }
    if (indisponivel) {
      return {
        backgroundColor: "#F5F5F5",
        color: "#E02020",
        text: "Indisponível",
      }
    }
    if (!novidade) {
      return {
        backgroundColor: "#F5F5F5",
        color: "#95C620",
        text: "Novidade",
      }
    }

    return null
  }

  const flag = getFlag(item)

  return (
    <S.CardContainer className="group">
      {flag && (
        <S.Flag backgroundColor={flag.backgroundColor} color={flag.color}>
          {flag.text}
        </S.Flag>
      )}
      {/* <Link href={`/${item.url_prod}`} passHref prefetch={true} scroll={true} shallow={false}> */}
      <a href={`/${item.url_prod}`}>
        <S.CardContent>
          <S.CardImageContent>
            <Image src={item.img_home_produto} alt={item.alt_image} width={170} height={213} objectFit="cover" />
          </S.CardImageContent>
          <S.CardDescriptionContent>
            <div className="header_content">
              <S.CardTitle>{item.prod_nome}</S.CardTitle>
              <S.CardSubtitle>COD.: {item.codprod}</S.CardSubtitle>
              <S.CardDescription>{item.caracteristicas}</S.CardDescription>
            </div>
            {listCoresWith5?.length > 1 && (
              <S.CoresContainer>
                <div className="header_cores">cores</div>
                <S.CoresContentContainer>
                  {listCoresWith5.map((cor) => (
                    <S.CoresContent color={cor.hex} border={cor.hex} twoColorsBg={cor.hex.split("/")} />
                  ))}
                  {restCoresLength > 0 && <span>{`+${restCoresLength}`}</span>}
                </S.CoresContentContainer>
              </S.CoresContainer>
            )}
          </S.CardDescriptionContent>
        </S.CardContent>
      </a>

      {/* </Link> */}

      <S.CardFooter>
        <S.CardFooterContent>
          <span>Disponível: {parseInt(itemState?.estoque ?? item.estoque).toLocaleString("pt-BR")}</span>
          {parseInt(itemState?.quantidade_estoque || item.quantidade_estoque) > 0 && (
            <span>
              Disponibilidade futura: +
              {parseInt(itemState?.quantidade_estoque || item.quantidade_estoque).toLocaleString("pt-BR")}
            </span>
          )}
        </S.CardFooterContent>

        {!indisponivel && (
          <S.CardPriceContent>
            <span className="label">A partir de</span>
            <span className="price">
              {formatPrice(itemState?.preco_home || item.preco_home)} <span className="asterixo">*</span>
            </span>
            <span className="label">a unidade</span>
          </S.CardPriceContent>
        )}

        {indisponivel && (
          <S.CardPriceContent>
            <S.ProductIndisponivel>
              <Link href={`/${item.url_prod}`} passHref>
                <a href={`/${item.url_prod}`}>Avise-me quando chegar</a>
              </Link>
            </S.ProductIndisponivel>
          </S.CardPriceContent>
        )}
      </S.CardFooter>

      {!indisponivel && (
        <div className="w-10 h-10 bg-slate-200 hidden group-hover:flex">
          <div className="hover-content">
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-2">
                <Link href={`/${item.url_prod}`} passHref prefetch={true} scroll={true} shallow={false}>
                  <a href={`/${item.url_prod}`}>
                    <span className={`button ${!buttonHover ? "vermais" : "vermenos"} no-underline`}>+ detalhes</span>
                  </a>
                </Link>
                <button
                  className="button whatsapp"
                  onClick={() => {
                    handleOpenGetInformation(item)
                  }}
                  onMouseEnter={() => setButtonHover(true)}
                  onMouseLeave={() => setButtonHover(false)}
                >
                  + informações pelo whatsapp <AiOutlineWhatsApp size={20} />
                </button>
              </div>
              <S.CardPriceContentHover>
                <span className="label">A partir de</span>
                <span className="price">
                  {formatPrice(itemState?.preco_home || item.preco_home)} <span className="asterixo">*</span>
                </span>
                <span className="label">a unidade</span>
              </S.CardPriceContentHover>
            </div>
          </div>
        </div>
      )}
    </S.CardContainer>
  )
}
