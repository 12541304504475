import styled from "styled-components"

export const CardContainer = styled.div`
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;

  position: relative;

  max-width: 396px;
  width: 100%;
  height: 100%;
  max-height: 347px;
  border: 1px solid #cfcfcf;
  position: relative;
  transition: all 0.1s ease-in-out;

  overflow: hidden;

  font-family: "Open Sans", sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    font-family: "Open Sans", sans-serif;
  }

  color: #414042;

  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    color: #414042;
  }

  .hover-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 0%;
    bottom: 0;
    align-items: center;
    justify-content: center;

    gap: 2px;

    transition: all 0.1s ease-in-out;

    .button {
      max-width: 252px;
      width: 100%;
      outline: none;
      border: none;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      padding: 5px 8px;
      border-radius: 4px;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.1s ease-in-out;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }

    .button.whatsapp {
      background-color: #707070;

      gap: 8px;

      &:hover {
        background-color: #95c620;
      }
    }

    .button.vermenos {
      background-color: #707070;

      gap: 8px;

      a {
        color: #fff;
        text-decoration: none;
      }
    }

    .button.vermais {
      background-color: #ff4f00;

      gap: 8px;

      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  &:hover {
    .hover-content {
      height: 25%;
      background-color: #f9f9f9;
    }
  }
`

export const CardContent = styled.div`
  padding: 33px 11px 0px 11px;
  display: grid;
  grid-template-columns: 171px 1fr;
  gap: 16px;
  cursor: pointer;
`

export const CardImageContent = styled.div`
  width: 171px;
  height: 212px;
  border: 1px solid #cfcfcf;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const CardDescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .header_content {
    display: flex;
    flex-direction: column;
  }
`

export const CardTitle = styled.strong`
  font-size: 15px;
  font-weight: 700;
`

export const CardSubtitle = styled.span`
  font-size: 15px;
  font-weight: 400;
`

export const CardDescription = styled.p`
  padding-top: 1.4rem;
  color: #919191;
  font-size: 12px;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const CoresContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 8px;

  .header_cores {
    font-size: 13px;
    font-weight: 700;
    text-transform: lowercase;
    text-align: right;
  }
`

export const CoresContentContainer = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: flex-end;

  span {
    font-size: 13px;
    font-weight: bold;
  }
`

export const CoresContent = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  border: 1px solid ${({ color }) => (color === "#ffffff" ? "#CFCFCF" : color)};

  ${(props) =>
    props.twoColorsBg?.length > 1 &&
    ` 
        background: rgb(0,0,0);
        background: linear-gradient(-45deg, ${props.twoColorsBg[0]} 50%, ${props.twoColorsBg[1]} 50%);
    `}
`

export const CardFooter = styled.div`
  display: flex;
  width: 100%;
  padding-inline: 11px;
  padding-block: 5px;
  height: 100%;
  justify-content: space-between;

  background-color: #f9f9f9;
`

export const CardFooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3px;
  width: 1fr;

  span {
    font-size: 12.6px;
  }
`

export const ProductIndisponivel = styled.button`
  outline: none;
  border: none;

  max-width: 112px;
  width: 100%;

  padding: 5px 4px;

  border: 1px solid #707070;
  border-radius: 8px;

  line-height: 1;

  transition: all 0.1s ease-in-out;

  span {
    font-size: 14px;
    color: #414042;
    font-weight: 600;
  }

  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
`

export const CardPriceContent = styled.div`
  display: flex;
  width: 132px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .label {
    font-size: 10px;
    font-weight: 400;
  }

  .price {
    font-size: 18px;
    font-weight: 700;
    position: relative;
  }

  .asterixo {
    font-size: 10px;
    position: absolute;
    top: 3px;
    right: -6px;
  }

  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0rem;
    transform: translateY(-50%);
    width: 2px;
    height: 60%;
    background-color: #cfcfcf;
    opacity: 0.5;
  }
`

export const CardPriceContentHover = styled.div`
  display: flex;
  width: 122px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .label {
    font-size: 10px;
    font-weight: 400;
  }

  .price {
    font-size: 18px;
    font-weight: 700;
    position: relative;
  }

  .asterixo {
    font-size: 10px;
    position: absolute;
    top: 3px;
    right: -6px;
  }

  position: relative;

  @media (max-width: 768px) {
    width: 90px;
  }
`

export const Flag = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px 8px;
  max-width: 182px;
  width: 100%;

  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};

  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
`
